/* ----- Font Styles: ----- */
@font-face {
  font-family: "Chamelton 30 Blk 2";
  src: url("fonts/Chamelton30-Blk2.woff2") format("woff2"),
    url("fonts/Chamelton30-Blk2.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DINPro";
  src: url("fonts/DINPro-Medium.woff2") format("woff2"),
    url("fonts/DINPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DINPro";
  src: url("fonts/DINPro-Bold.woff2") format("woff2"),
    url("fonts/DINPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}
